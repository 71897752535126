.placeholders {
  width: 25%;
  padding: 0 10px 0 0;
  color: var(--text);

  .form-section {
    .panels {
      display: flex;
      flex-direction: column;

      .panel {
        height: 200px;
        overflow-y: auto;
        border-right: 1px solid var(--border-outline);
        border-left: 1px solid var(--border-outline);
        padding: 10px 0 10px 20px;

        &:first-child {
          padding-bottom: 40px;
        }

        &:first-child,
        &:last-child {
          border-bottom: 1px solid var(--border-outline);
        }

        .panel-tree-container {
        }

        .panel-placeholders {
          .placeholder-item {
            cursor: pointer;
            border-radius: 4px;
            padding: 0 4px;
            margin-right: 20px;

            &:hover {
              color: var(--dark-blue);
              background-color: var(--light-blue);
            }
          }
        }
      }

      .hint-panel {
        max-height: 40px;

        .hint {
          .hint-title {
            font-weight: 700;
          }
        }
      }

      .panel-title {
        background-color: var(--form-header);
        border: 1px solid var(--border-outline);
        font-weight: 700;
        padding: 10px 20px;
        svg {
          padding: 0 4px;
        }
      }
    }
  }
}
