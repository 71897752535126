.waypoint-wrap {
  border-radius: 4px 4px 0 0;

  .waypoint-row {
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid var(--border-outline);

    .dropdown-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--light-grey);
      min-width: 30px;
      height: 50px;
      cursor: pointer;
      border: none;
      border-right: 1px solid var(--border-outline);
    }

    .wr-entity {
      grid-template-columns: none;
      display: flex;
      flex: 1;

      .form-field-label {
        margin-left: 20px;
      }

      .dd-main {
        width: 200px;
        flex: 1;
      }
    }

    .wr-address {
      grid-template-columns: none;
      display: flex;
      flex: 1;

      .form-field-label {
        margin-left: 20px;
      }

      .dd-main {
        width: 300px;
        flex: 1;
      }
    }

    .wr-time {
      grid-template-columns: none;
      display: flex;

      .form-field-label {
        margin-left: 20px;
      }

      input {
        width: 70px;
      }
    }

    .waypoint-button-grouping {
      display: flex;
      margin: 0 10px 0 20px;

      .ib-wrap {
        display: block;
        max-width: fit-content;
        height: 30px;
        margin: 0 0 0 3px;

        .ib-icon {
          height: 30px;
          min-width: 30px;
        }
      }
    }
  }

  .waypoint-content {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background-color: var(--light-grey);
    border: 1px solid var(--border-outline);
    border-top: none;
    border-radius: 0 0 4px 4px;

    .waypoint-info-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;
      height: 33px;
      grid-gap: 8px;
      gap: 8px;

      .waypoint-info-label {
        flex: 1;
        text-align: right;
        font-size: 13px;
        font-weight: 600;
        color: var(--label);
      }

      .waypoint-info-content {
        display: flex;
        flex: 11;

        input {
          flex: 1;
          color: var(--text);
          border: 1px solid var(--border-outline);
          border-radius: 4px;
          padding: 7px;
          outline: none;
        }
      }

      .waypoint-info-actual-time {
        display: flex;
        flex: 1;

        input {
          flex: 1;
          color: var(--text);
          border: 1px solid var(--border-outline);
          border-radius: 4px;
          padding: 7px;
          outline: none;
        }
      }
    }
  }
}
