.document-row {
  svg {
    margin: auto;
    display: block;

    &.dr-success {
      color: var(--green);
    }

    &.dr-warn {
      color: var(--waste-yellow);
    }
  }
}
