.page-wrapper {
  display: flex;
  flex-direction: column;
}

.page-wrapper-body {
  display: flex;
  flex-direction: row;
}

.page-wrapper-top {
  height: 50px;
}

.page-wrapper-left {
  background-color: var(--black);
  z-index: 99;
  max-width: 246px;
}

.page-wrapper-content {
  min-height: calc(100vh - 50px);
  flex: 9;
  background-color: var(--main-background);
  overflow-x: auto;

  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: var(--text);
  }
}
