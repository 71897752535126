.email-template-index {
  background-color: var(--main-background);
  .page-content {
    padding: 10px;
  }
  .index-table {
    th {
      width: 25%;
    }
    td {
      width: 25%;
      .actions {
        display: flex;
        justify-content: center;
        gap: 16px;
      }
    }
    td:last-child {
      width: 325px;
      max-width: none;
    }
    td:first-child {
      color: var(--main-blue);
      svg {
        margin-right: 11px;
      }
      .faded {
        opacity: 0.3;
      }
    }
    .coloured {
      background-color: var(--main-background);
    }
  }
}
