.label-container {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  height: 40px;
  font-size: 13px;
  user-select: none;
  background-color: #181b1f;
  color: var(--white);
  font-weight: 500;

  .lb-icon {
    width: 40px;
  }

  .lb-text {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
}
