@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  font: 13px 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body * {
  font-family: inherit;
  font-size: inherit;
}
a {
  color: var(--main-blue);
}
