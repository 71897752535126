.links-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  user-select: none;
  outline: 1px solid var(--border-outline);

  .toggle-links {
    height: 100%;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .quick-links {
    display: none;
    background-color: var(--white);
    color: var(--text);
    flex-direction: column;
    position: absolute;
    width: 160%;
    top: 50px;
    left: 0;
    z-index: 9;
    padding: 5px;
    outline: 1px solid var(--border-outline);
    border-radius: 1px;
    :nth-child(4) {
      border-top: 1px solid var(--border-outline);
    }
    .link {
      padding-left: 16px;
      height: 32px;
      font-size: 16px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: var(--main-blue);
        border-radius: 3px;
        color: var(--white);
      }
    }
  }
}
.links-container:hover {
  .quick-links {
    display: flex;
  }
}
