.contact-details-form {
  position: relative;
  margin: 15px;

  // Adds a margin to the top iterative input component
  :nth-child(4) {
    margin: 10px 0 0 0;
  }

  .footer-button {
    position: absolute;
    bottom: -47px;

    .ib-wrap {
      height: 32px;
      max-width: 160px;

      .ib-icon {
        min-width: 30px;
      }

      .ib-button {
        font-size: 12px;
      }
    }
  }
}
