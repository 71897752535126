.tariff-area {
  h1 {
    font-size: 15px;
    color: var(--dark-grey);
  }

  .page-content {
    padding: 10px;

    .form-section {
      .area-headers {
        display: flex;
        border-bottom: 1px solid var(--grey-2);
        margin-bottom: 10px;
        padding-bottom: 5px;

        div {
          flex: 1;
          font-weight: 600;
          color: var(--text);
        }

        .ah-actions {
          max-width: 60px;
        }
      }

      .ma-row {
        display: flex;

        &:not(:last-child) {
          margin: 0 0 20px 0;
          padding: 0 0 20px 0;
          border-bottom: 1px solid var(--grey-2);
        }

        .ma-col {
          flex: 1;

          &.postcodes {
            border: 1px solid var(--border-outline);
            padding: 10px;

            .ti-wrap {
              .ti-input {
                width: calc(100% - 20px);
              }
            }
          }

          &.name {
            input {
              margin-left: 20px;
              width: calc(100% - 80px);
            }
          }

          &.actions {
            max-width: 60px;
            justify-content: center;
            display: flex;
            padding: 0;

            .ib-wrap {
              height: 32px;
              max-width: 32px;

              .ib-icon {
                min-width: 32px;
              }
            }
          }

          input {
            color: var(--text);
            border: 1px solid var(--border-outline);
            border-radius: 4px;
            padding: 6px 10px;
            outline: none;
            width: 80%;

            &:focus {
              border: 1px solid var(--dark-grey);
            }
          }
        }
      }
    }

    .ta-button-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      margin: 10px 0 0 0;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 110px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
