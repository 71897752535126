.new-address {
  min-width: 500px;

  .af-button-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
