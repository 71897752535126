.checkbox {
  display: flex;
  align-items: center;

  .switch {
    display: inline-flex;
    position: relative;
    align-items: center;
    height: 22px;
    width: 40px;
    background-color: var(--black);
    border-radius: 4px;
    padding: 0 2px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
    }

    input:checked + div {
      transform: translate3d(22px, 0, 0);
    }

    div {
      height: 18px;
      width: 18px;
      background-color: var(--white);
      border-radius: 4px;
      transition: all 300ms;
      z-index: 3;
    }

    svg {
      position: absolute;
      color: var(--white);
      z-index: 1;

      &:first-child {
        left: 7px;
        font-size: 12px;
      }

      &:last-child {
        right: 7px;
        font-size: 13px;
      }
    }
    &:focus-within {
      outline: 1px solid var(--icon-color);
      outline-offset: 1px;
    }
  }

  .switch.checked {
    background-color: var(--green);
  }

  .checkbox-text {
    margin: 0 0 0 8px;
  }
}

.reverse {
  flex-direction: row-reverse;

  .checkbox-text {
    margin: 0 10px 0 0;
  }
}

.disabled {
  .checkbox-text {
    color: var(--grey-2) !important;
  }

  .switch {
    cursor: not-allowed !important;
    background-color: var(--grey-1) !important;
  }
}

.grid {
  display: grid;

  .checkbox {
    display: flex;
    justify-content: flex-end;
  }
}
