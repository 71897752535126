.show-booking-page {
  .page-content {
    padding: 10px;
  }

  .tab-content {
    background-color: var(--white);
    padding: 10px;
  }

  .form-section {
    margin: 0 0 10px 0;
  }

  h1 {
    color: var(--text);
    font-size: 16px;
  }
}
