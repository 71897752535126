.toolbar-link {
  display: flex;
  position: relative;

  .tbl-icon {
    height: 30px;
    width: 30px;
    background: none;
    outline: none;
    border: none;
    color: var(--text);
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--black);
    }
  }

  .tbl-menu {
    position: absolute;
    top: 30px;
    background-color: white;
    z-index: 99;
  }
}
