.email-tab {
  .attachment-section {
    .form-section {
      padding: 10px;
    }
  }

  .show-tab-content {
    .form-section {
      .form-section-body {
        .show-table {
          table {
            tbody {
              tr {
                td {
                  font-size: 13px;
                  .ib-wrap {
                    margin: 0 10px 0 0;
                    height: 30px;
                    max-width: 89px;

                    .ib-icon {
                      min-width: 30px;
                    }

                    .ib-button {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
