button:disabled {
  opacity: 0.5;
  .ib-icon {
    cursor: not-allowed !important;

    svg {
      cursor: not-allowed !important;
    }
  }

  .ib-button {
    cursor: not-allowed !important;
  }
}

.ib-wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  border: none;
  padding: 0;
  height: 40px;
  border-radius: 4px;
  width: 100%;

  &:hover,
  &:focus {
    filter: brightness(90%);
  }

  .ib-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    background-color: var(--dark-blue);
    color: var(--white);
    border: 1px solid var(--dark-blue);
    border-radius: 4px 0 0 4px;
    cursor: pointer !important;

    svg {
      cursor: pointer !important;
    }
  }

  .ib-button {
    display: flex;
    font-size: 13px;
    width: 100%;
    background-color: var(--main-blue);
    color: var(--white);
    border: none;
    outline: none;
    border-radius: 0 4px 4px 0;
    user-select: none;
    white-space: nowrap;
    cursor: pointer !important;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  .rounded {
    border-radius: 4px;
  }
}

.import-job {
  .ib-icon {
    background-color: var(--dark-yellow);
    border: 1px solid var(--dark-yellow);
  }
  .ib-button {
    background-color: var(--yellow);
  }
}

.small {
  height: 30px !important;
  max-width: fit-content !important;

  .ib-icon {
    min-width: 30px;
  }

  .ib-button {
    max-width: fit-content !important;
    font-size: 12px;
  }
}

.destructive {
  .ib-icon {
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
  }

  .ib-button {
    background-color: var(--red);
  }

  &.icon-only {
    .ib-icon {
      background-color: var(--red);
      border: 1px solid var(--red);
    }
  }
}

.success {
  .ib-icon {
    background-color: var(--dark-green);
    border: 1px solid var(--dark-green);
  }

  .ib-button {
    background-color: var(--green);
  }
}

.subwindow {
  .ib-icon {
    background-color: var(--green);
    border: 1px solid var(--green);
  }
}

.xlsx-button {
  .ib-icon {
    background-color: var(--blue-3);
    border: 1px solid var(--blue-3);
  }
}

.success-disabled {
  .ib-icon {
    background-color: var(--dark-green) !important;
    border: 1px solid var(--dark-green) !important;
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  .ib-button {
    background-color: var(--green) !important;
    opacity: 0.6;
    cursor: not-allowed !important;
  }
}

.edit {
  .ib-icon {
    background-color: var(--grey-1);
    border: 1px solid var(--grey-1);
    color: var(--black);
  }

  .ib-button {
    background-color: var(--grey-2);
    color: var(--black);
  }
}

.warning {
  .ib-icon {
    background-color: var(--dark-yellow);
    border: 1px solid var(--dark-yellow);
  }

  .ib-button {
    background-color: var(--yellow);
  }

  &.icon-only {
    .ib-icon {
      background-color: var(--yellow);
      border: 1px solid var(--yellow);
    }
  }
}

.mail-attachment {
  .ib-icon {
    background-color: var(--white);
    border-color: var(--border-outline);
    border-right: none;
    color: var(--text);
  }

  .ib-button {
    background-color: var(--white);
    color: var(--text);
    border: 1px solid var(--border-outline);
  }
}

.solid-grey {
  .ib-icon {
    background-color: var(--text);
    color: var(--white);
  }

  .ib-button {
    background-color: var(--text);
    color: var(--white);
  }
}
