.accounts-sync {
  .page-content {
    padding: 10px;

    .as-footer {
      display: flex;
      padding: 10px;
      background-color: var(--white);

      .as-selected-info {
        height: fit-content;
        margin: auto 0;
      }

      .ib-wrap {
        max-width: 120px;
        margin: 0 20px 0 auto;
        height: 32px;
      }
    }
  }

  td {
    width: 10%;

    &.doc-select {
      max-width: 10px;
      width: 1%;

      input {
        display: block;
        margin: auto;
        border: 2px var(--grey-1) solid;
        background-color: var(--white);

        &:checked {
          background-color: var(--green);
          border-color: var(--green);
        }
      }
    }
  }

  th {
    input {
      display: block;
      margin: auto;
      border: 2px var(--grey-1) solid;
      background-color: var(--white);

      &:checked {
        background-color: var(--green);
        border-color: var(--green);
      }
    }
  }
}
