.breadcrumbs {
  display: flex;
  align-items: center;
  height: 30px;
  background-color: var(--main-background);
  border-bottom: 1px solid var(--border-outline);
  .elements {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumbs {
  label {
    a {
      color: var(--dark-grey);
      text-decoration: none;
      margin-left: 8px;
    }

    svg {
      color: var(--dark-grey);
      text-decoration: none;
      margin-left: 8px;
    }

    cursor: pointer;
  }

  label:hover {
    a {
      color: var(--black);
    }

    svg {
      color: var(--black);
    }
  }
}
