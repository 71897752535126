.error-message-container {
  position: fixed;
  top: 50px;
  right: 5px;
  background-color: var(--light-golden);
  display: flex;
  flex-direction: row-reverse;
  color: var(--dark-golden);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--golden);
  z-index: 5;

  &.success {
    background-color: var(--alert-green);
    border: 1px solid var(--green);
    color: var(--dark-green);
  }

  .error-message-content {
    flex: 1;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
  }

  .icon {
    cursor: pointer;
  }
}
