.show-entity {
  .page-content {
    padding: 10px;

    .tab-content {
      background-color: var(--white);
      padding: 10px;
    }
    .body {
      .tab-content-grid {
        display: flex;
        flex: 1;

        .form-section {
          padding: 10px;
          flex: 1;
        }
      }
    }
  }
}
