.id-wrap {
  background-color: var(--grey-1);

  .short {
    .form-field-label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 13px;
      font-weight: normal;
    }
  }
}
