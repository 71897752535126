.contact-function-form {
  margin: 20px;

  .cff-cb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
  }

  .footer-button {
    position: absolute;
    bottom: 21px;

    .ib-wrap {
      height: 32px;
      max-width: 160px;

      .ib-icon {
        min-width: 30px;
      }

      .ib-button {
        font-size: 12px;
      }
    }
  }
}
