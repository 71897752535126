.show-tab-content {
  padding: 10px;
  background-color: var(--white);

  .form-section-body {
    padding: 0;
    background-color: var(--white);
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--text);
    .company-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid var(--border-outline);
      height: 120px;
      padding-left: 20px;
      .sm-detail {
        font-size: 13px;
      }
      .company-name {
        font-weight: 600;
        font-size: 24px;
        margin: 5px 0;
        a {
          text-decoration: none;
          color: var(--main-blue);
          &:visited {
            color: var(--main-blue);
          }
        }
      }
    }
    .details-container {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid var(--border-outline);
      padding-left: 20px;

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 23px 0 20px 0;
        width: 15%;
        .sm-detail {
          font-size: 13px;
        }
        .bg-detail {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .detail:last-child {
        background-color: var(--main-background);
        border-left: 1px solid var(--border-outline);
        margin-left: auto;
        align-items: flex-end;
        padding-right: 30px;
      }
      .amount {
        width: 20%;
        .sm-detail,
        .bg-detail {
          padding-right: 15%;
        }
      }
    }
    table {
      width: 95%;
    }
  }
}
