.cf-wrap {
  display: flex;
  flex: 1;
  margin: 0 0 5px 0;

  .cf-label {
    display: flex;
    position: relative;
    flex: 2;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px 0 0 4px;
    padding: 0 10px 0 0;
    font-size: 13px;
    font-weight: bold;
    color: var(--text);
    background-color: var(--border-outline);

    svg {
      margin: 0 0 0 7px;
    }

    .cf-types {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 37px;
      left: 0px;
      border: 1px solid var(--grey-1);
      background: var(--main-background);
      padding: 5px 0;
      z-index: 99;

      .cf-type {
        width: 160px;
        height: 30px;
        border: 0;
        outline: none;
        cursor: pointer;
        background: var(--main-background);
        text-align: left;
        padding-left: 18px;
        z-index: 99;

        &:hover {
          background: var(--main-blue);
          color: var(--white);
        }
      }
    }
  }

  .cf-children {
    display: flex;
    flex: 10;
    max-height: 35px;

    .dd-main {
      flex: 1;
      max-height: 35px;

      .dd-current {
        display: flex;
        align-items: center;
        border-radius: 0 4px 4px 0;
        height: 35px;

        svg {
          top: 7px;
        }
      }

      .dd-content {
        .dd-input {
          height: 35px;
          padding: 0 0 0 10px;
        }
      }
    }

    input {
      flex: 1;
      color: var(--text);
      border: 1px solid var(--border-outline);
      border-radius: 0 4px 4px 0;
      padding: 10px;
      outline: none;

      &:focus {
        border: 1px solid var(--dark-grey);
      }
    }

    .cf-button {
      height: 35px;
      width: 35px;
      outline: none;
      border: none;
      border-radius: 0 4px 4px 0;
      color: var(--white);
      cursor: pointer;

      &.close {
        background-color: var(--red);

        &:hover,
        &:focus {
          background-color: var(--dark-red);
        }
      }

      &.add {
        background-color: var(--green);

        &:hover,
        &:focus {
          background-color: var(--dark-green);
        }
      }
    }
  }
}
