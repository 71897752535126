.invoices {
  margin: 10px;
  background-color: var(--white);

  .page-content {
    margin: 10px;

    .act-row {
      width: 7%;
    }
  }
}

.invoice-button {
  height: 30px;
  width: fit-content;
  margin: auto;
  .ib-icon {
    min-width: 30px;
  }
  .ib-button {
    font-size: 12px;
    padding: 0 13px;
  }
}
