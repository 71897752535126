.svd-modal {
  display: flex;
  flex-direction: column;

  .message {
    margin-top: 0;
    margin-right: 0;
  }
  .svd-results {
    width: 60vw;
    height: 40vh;
    overflow-y: scroll;
    .svd-results-table {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .svd-results-row {
        display: grid;
        grid-template-columns: 3% 16% 16% 60%;
        color: var(--text);
        &.result {
          padding-bottom: 8px;
          border-bottom: 1px solid #ececec;
        }
        .svd-header {
          font-weight: bold;
          grid-column: span 1;
          .job-number {
            grid-column: 2/3;
          }
          &.details {
            flex: 1;
          }
        }
        .svd-cell {
          grid-column: span 1;
          &.greyed-out {
            color: var(--dark-grey);
          }
          &.error {
            color: var(--red);
          }
        }
      }
    }

    .svd-error {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--red);
    }
  }

  .svd-buttons {
    margin-top: 16px;

    align-self: flex-end;
    .svd-done {
      height: 32px;
      width: fit-content;

      .ib-icon {
        min-width: 32px;
      }
    }
  }
}
