.vrsj-section {
  margin-bottom: 10px;

  .vrsjs-title {
    display: flex;

    .vrsjs-icon {
      margin: auto;
      margin-right: 20px;
    }

    .status-icon {
      height: 10px;
      width: 10px;
      vertical-align: 0em;
      margin-right: 5px;

      &.active {
        color: var(--green);
      }

      &.pending {
        color: var(--grey-1);
      }

      &.finalised {
        color: var(--main-blue);
      }

      &.cancelled {
        color: var(--dark-red);
      }
    }

    hr {
      border: 1px solid var(--grey-1) !important;
      height: 10px !important;
      margin: 4px 10px !important;
      width: 0 !important;
    }
  }

  .vrsjs-content {
    display: flex;
    color: var(--text);

    .vrsjs-row {
      flex: 1;

      &.extended {
        flex: 2;
      }

      .vrsjs-header {
        font-size: 13px;
        font-weight: 600;
        white-space: pre;
      }
    }
  }
}
