.line-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--grey-2);
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-size: 13px;

  span {
    background: var(--white);
    padding: 0 30px;
  }
}
