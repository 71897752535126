.attachment-section {
  .form-section {
    .form-section-body {
      display: flex;
      flex-wrap: wrap;
      max-height: 80px;
      overflow: auto;

      .file-wrap {
        display: flex;
        flex-direction: row;
        height: 28px;
        color: var(--text);
        margin: 5px 15px 0 0;
        font-size: 13px;
        white-space: nowrap;

        .file-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          border: 1px solid var(--border-outline);
          border-radius: 4px 0 0 4px;
        }

        .file-text {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          border: 1px solid var(--border-outline);
          border-left: none;
          cursor: pointer;
        }

        .file-remove {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--white);
          background-color: var(--red);
          width: 30px;
          border: 1px solid var(--red);
          border-left: none;
          border-radius: 0 4px 4px 0;
          cursor: pointer;

          &:hover,
          &:focus {
            background-color: var(--dark-red);
            border: 1px solid var(--dark-red);
            border-left: none;
          }
        }
      }
    }
  }
}
