.email-template {
  width: 75%;
  .template-form {
    .form-header {
      padding: 20px;
      border-left: 1px solid var(--border-outline);
      border-right: 1px solid var(--border-outline);
    }

    .salutation {
      padding: 5px;
      border-left: 1px solid var(--border-outline);
      border-right: 1px solid var(--border-outline);
      border-top: 1px solid var(--border-outline);
      background-color: var(--main-background);
      color: var(--text);
    }

    .signature {
      padding: 20px;
      border-left: 1px solid var(--border-outline);
      border-right: 1px solid var(--border-outline);
      border-bottom: 1px solid var(--border-outline);
      background-color: var(--main-background);
    }
  }
}
