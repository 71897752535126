.c-table-section {
  margin: 10px;
  border-radius: 2px;

  .c-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid var(--border-outline);
    border-bottom: none;
    border-radius: 2px 2px 0 0;
    color: var(--white);
    user-select: none;
    font-weight: bold;

    .checkbox {
      .checkbox-text {
        color: var(--black);
        font-weight: 400;
      }
    }
    .c-table-header-section {
      display: flex;

      .c-table-header-icon {
        padding: 5px 10px;
        border-radius: 3px;
        color: var(--white);
        display: flex;
        justify-content: center;
        margin-right: 15px;
        cursor: pointer;

        svg {
          width: 1em;
          margin: auto;
        }
      }

      .c-table-title-split {
        font-weight: 700;
      }

      .id-button {
        height: 30px;
        border-radius: 4px;

        .id-icon {
          min-width: 30px;
        }

        .id-arrow {
          top: 6px;
        }
      }

      .id-content {
        z-index: 2;
      }

      input {
        border-radius: 4px;
        border: 1px solid var(--grey-1);
        padding: 0 7px;
        margin: auto;
        margin-right: 10px;
        height: 25px;
      }
    }
  }

  .c-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid var(--border-outline);
    border-top: none;
    border-radius: 0 0 2px 2px;
    color: var(--white);
    user-select: none;
    overflow: hidden;
  }

  .c-table-body {
    flex-direction: column !important;
    border-radius: 0 0 2px 2px;
    border-left: 1px solid var(--border-outline);
    border-right: 1px solid var(--border-outline);
    border-bottom: 1px solid var(--border-outline);
    overflow-y: visible;

    border-collapse: collapse; /* Removes the cell spacing via CSS while maintaining the ability to use borders */

    .c-table-headers {
      text-align: left;

      .header {
        font-size: 11px;
        padding: 5px 0;
        margin-right: 10px;
        cursor: pointer;

        .header-content {
          display: flex;

          .sort-icon {
            margin: auto 10px;
          }
        }

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          margin-left: 5px;
        }

        &.no-sort {
          cursor: not-allowed;
        }
      }
    }

    .c-table-content {
      background-color: var(--main-background);

      .row {
        color: var(--text);
        height: 40px;
        background-color: var(--white);
        border-bottom: 1px solid var(--border-outline);
        display: flex;

        &:hover {
          background-color: var(--main-background) !important;
        }

        .cell {
          display: flex;
          color: var(--text);
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }

          &.no-width {
            flex: 1;
          }
          
          &.flags {
            overflow-x: initial;
          }
        }

        .large {
          a,
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      hr {
        width: 100%;
        border: none;
        background: var(--border-outline);
        height: 1px;
        margin: 10px 0;
      }

      .body-section {
        display: flex;
        flex-direction: column;
        flex: 1;

        h1 {
          font-size: 13px;
          color: var(--text);
          margin: 0 0 15px 0;
        }

        .text-line {
          margin: 5px 0 0 0;
          align-items: center;
          color: var(--text);
        }

        .icon-line {
          display: flex;
          align-items: center;
          margin: 0 0 10px 0;

          svg {
            width: 20px;
            font-size: 16px;
            color: var(--main-blue);
            margin: 0 5px 0 0;
          }

          em {
            color: var(--text);
          }

          .warning-icon {
            color: var(--yellow);
          }
        }
      }

      .button-section {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .button-line {
          display: flex;
          justify-content: flex-end;

          .ib-wrap {
            margin: 5px 0 0 10px;
          }
        }
      }
    }
  }

  .c-table-transition-enter {
    max-height: 0;
    overflow-y: auto;
  }

  .c-table-transition-enter-active {
    transition: max-height 200ms ease, padding 200ms ease;
    max-height: 250px;
    overflow-y: auto;
  }

  .c-table-transition-enter-done {
    max-height: auto;
    overflow-y: visible;
  }

  .c-table-transition-exit {
    max-height: 250px;
    overflow-y: auto;
  }

  .c-table-transition-exit-active {
    transition: max-height 200ms, padding 200ms ease;
    max-height: 0;
    overflow-y: auto;
  }

  .c-table-transition-exit-done {
    max-height: 0;
    overflow-y: auto;
  }
}
