.toolbar-icon-button {
  height: 30px;
  width: 30px;
  background: none;
  outline: none;
  border: none;
  color: var(--text);
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--black);
  }
}
