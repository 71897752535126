.toolbar-colour-select {
  display: flex;
  position: relative;

  .tcs-icon {
    height: 30px;
    width: 30px;
    background: none;
    outline: none;
    border: none;
    color: var(--text);
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--black);
    }
  }

  .tcs-menu {
    display: block;
    width: 90px;
    position: absolute;
    top: 30px;
    background-color: white;
    border: 1px solid var(--grey-1);
    z-index: 99;

    button {
      height: 30px;
      width: 30px;
      outline: none;
      border: none;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--grey-1);
      }
    }
  }
}
