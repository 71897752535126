.email-template-new {
  .page-content {
    .template-title {
      color: var(--dark-grey);
      font-weight: 700;
      font-size: 16px;
      .latter-title {
        font-weight: 500;
      }
    }
    padding: 10px;

    .tab-content {
      display: flex;
      background-color: var(--white);
      // padding: 10px;
    }
    .button-group {
      display: flex;
      padding: 10px 10px 20px 0;
      background-color: var(--white);
      flex-direction: row;
      justify-content: flex-end;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 90px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
