.flag-icon {
  background-color: var(--border-outline);
  padding: 5px;
  margin-right: 3px;
  border-radius: 3px;
  height: 12px;
  width: 12px;
  justify-content: center;
  display: flex;

  svg {
    vertical-align: 0em;
    margin: auto;
    height: 0.75em;
  }
}
