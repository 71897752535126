.vrsr-entry {
  margin-right: 0;
  padding-right: 0;
  position: relative;
  height: 200px;
  padding: 0 77.5px;
  color: var(--text);

  .vrsre-icon {
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    left: 50px;
    right: auto;
    top: 77.5px;
    text-align: center;
    font-size: 16px;
    overflow: hidden;
    z-index: 3;
    background: var(--white);
    color: var(--main-blue);
    border: 3px solid var(--main-blue);
  }

  .vrsre-lock {
    display: block;
    position: absolute;
    left: 115px;
    right: auto;
    top: 92.5px;
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    z-index: 3;
  }

  .vrsre-content {
    position: relative;
    padding: 0 83px;
    margin: 0;
    min-height: 32px;
    display: flex;
    height: 200px;

    .vrsre-col {
      flex: 1;
      margin-top: 40px;
    }
    .vrsre-address {
      margin: auto;
    }

    .vrsre-header {
      font-size: 13px;
      font-weight: 600;
      white-space: pre;
    }

    .vrsre-row {
      margin-bottom: 40px;
    }
  }
}
