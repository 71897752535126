.vehicle-top-bar {
  display: flex;
  min-height: 50px;
  background-color: var(--white);
  color: var(--black);
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-outline);

  .top-bar-section {
    display: flex;

    .spinner {
      height: initial;
      width: initial;
    }

    .icon-button {
      height: 50px;
      width: 56px;
      border: none;
      background-color: var(--white);
      color: var(--black);
      border-right: 1px solid var(--border-outline);
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: var(--dark-grey);
      }
    }

    .not-connected {
      justify-content: center;
      display: flex;
      margin: 0px 10px;

      svg {
        margin: 0 5px;
      }

      .retry-button {
        background: none;
        border: 0;
        text-decoration: underline;
        color: var(--main-blue);
        cursor: pointer;

        &:hover {
          color: var(--dark-blue);
        }
      }
    }

    .closed {
      transform: rotateY(180deg);
      border-left: 1px solid var(--border-outline);
    }

    .company-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 50px;
      border-right: 1px solid var(--border-outline);
      cursor: pointer;
      background-color: var(--logo-blue);

      img {
        height: 36px;
      }
    }
    .development {
      background-color: var(--development-color);
    }

    .top-bar-user {
      display: flex;
      position: relative;
      height: 50px;
      align-items: center;
      padding: 0 10px 0 0;
      color: var(--black);
      font-size: 16px;
      white-space: nowrap;
      cursor: pointer;
      .user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }

      .top-bar-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-2);
        color: var(--dark-grey);
        padding: 4px;
        margin: 10px;
        font-size: 15px;
        border-radius: 200px;
        min-width: 25px;
        min-height: 25px;
      }

      .top-bar-user-dropdown {
        display: none;
        position: absolute;
        background-color: var(--white);
        top: 50px;
        left: -1px; /* to keep it inline with borderbox */
        border: 1px solid var(--border-outline);

        .lb-container {
          background-color: var(--white);
          color: var(--text);
          max-width: 149px;
          padding: 0 0 0 10px;

          /* All but last link should have a border bottom */
          &:not(:last-child) {
            border-bottom: 1px solid var(--border-outline);
          }

          .lb-text {
            margin: 0 0 0 10px;
          }

          &:hover {
            color: var(--black);
          }
        }
      }

      &:hover {
        .top-bar-user-dropdown {
          display: block;
          z-index: 99;
        }
      }
    }
  }
}
