.job-modal{
  min-width: 550px;

    .job-ref {
      padding: 16px 0;
      border-top:  1px var(--border-outline) solid;
      border-bottom:  1px var(--border-outline) solid;
    }
    .af-button-line {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 24px 0 18px 0;
      .ib-wrap {
        margin: 0 10px 0 0;
      }
    }
  }
  