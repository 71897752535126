.show-table {
  padding: 50px 0 0 20px;
  table {
    width: 95%;
    border-collapse: collapse;
    th {
      padding-bottom: 5px;
      border-bottom: 1px solid var(--white);
    }
    th:last-child,
    td:last-child {
      text-align: right;
    }
    .edit-head {
      width: 10%;
      text-align: left !important;
    }
    td {
      padding: 5px 0 5px 0;
    }
    tr {
      border-bottom: 1px solid var(--border-outline);
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--main-blue);
      &:visited {
        color: var(--main-blue);
      }
    }
  }
  .total-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--border-outline);
    font-weight: 600;
    font-size: 16px;
    padding-left: 3px;
    width: 95%;
    padding-top: 20px;
    margin-bottom: 60px;
  }
}
