.all-entities {
  .table {
    margin: 10px;
  }
  td {
    width: 40%;
  }
  .small {
    width: 10%;
  }
}

.new-entity {
  .af-button-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
