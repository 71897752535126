.emails-tab {
  background-color: var(--white);
  .page-content {
    .c-form-section-body,
    .c-form-section-transition-enter-done {
      padding: 0;
    }
    color: var(--text);
    .recipients {
      padding: 10px 20px;
      border-bottom: 1px solid var(--border-outline);
      .recipient-type {
        font-weight: 700;
      }
    }
    .email-body {
      padding: 10px 20px;
      background-color: var(--main-background);
    }
    .attachments {
      padding: 10px 20px;
      display: flex;
      gap: 10px;
    }
    .no-email {
      font-style: italic;
    }
  }
}
