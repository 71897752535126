.all-vehicle-runs {
  .page-content {
    padding: 10px;

    .table {
      .table-top {
        .section {
          .vri-selector-group {
            display: flex;

            .selector-value {
              &:not(:last-child) {
                margin: 0 5px 0 0;
              }

              svg {
                right: 14px;
              }
            }
          }
        }
      }
    }
  }
}
