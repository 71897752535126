.user-form {
  .page-content {
    padding: 10px;

    h1 {
      font-size: 16px;
      color: var(--dark-grey);
    }

    .tab-content {
      padding: 10px;
      background-color: var(--white);

      .tab-content-grid {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr;
        padding: 10px;
        background-color: var(--white);
        .form-section {
          .form-section-body {
            .c-form-section {
              .c-form-section-body {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
              }
            }
          }
        }
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0 20px 10px;
      background-color: var(--white);
      flex-direction: row;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 90px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
