.small {
  width: 5%;
}

.medium {
  width: 10%;
}

.reference {
  width: 8%;
}

.value {
  width: 7%;
}
