.fuel-costs {
  h1 {
    font-size: 15px;
    color: var(--dark-grey);
  }

  .page-content {
    padding: 10px;

    .form-section {
      .fc-row {
        display: flex;

        &:not(:last-child) {
          margin: 0 0 20px 0;
          padding: 0 0 20px 0;
          border-bottom: 1px solid var(--grey-2);
        }

        .form-field {
          flex: 1;

          .ti-wrap {
            .ti-input {
              width: 100%;
            }
          }
        }

        .ib-wrap {
          align-self: flex-end;
          flex: 1;
          height: 32px;
          max-width: 32px;
          margin-left: 15px;

          .ib-icon {
            min-width: 32px;
          }
        }
      }
    }

    .ta-button-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 110px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
