.ti-container {
  display: flex;
  align-items: center;
  color: var(--text);

  input:read-only {
    user-select: none;
  }

  .ti-input {
    flex: 5;
    height: 30px;
    padding: 0 5px 0 10px;
    outline: none;
    cursor: text;
    border: 1px solid var(--border-outline);
  }

  .ti-input:first-child {
    border-radius: 4px 0 0 4px;
  }

  .ti-input:last-child {
    border-radius: 0 4px 4px 0;
  }

  .ti-gap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 32px;
    background: var(--grey-2);
  }
}
