.message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  font-size: 13px;
  padding: 0 20px;
  margin: 0 0 15px 0;
  background-color: var(--main-blue);
  color: var(--white);
  user-select: none;
  cursor: pointer;
  height: fit-content;
  min-height: 50px;
  .error-list {
    div:first-child {
      margin: 15px 0 0 0;
      font-weight: bold;
    }

    ul {
      padding-inline-start: 20px;
    }
  }
}
.message-success {
  background-color: var(--alert-green);
  border: 1px solid var(--border-green);
  margin: 10px 10px 0 0;
  color: var(--dark-green);
  font-weight: 500;
  .success-title {
    font-weight: 700;
  }
  margin-bottom: 16px;
}
.message-error {
  background-color: var(--alert-red);
  color: var(--dark-red);
  .no-title {
    padding: 10px 0;
  }
}
.message-warning {
  background-color: var(--light-golden);
  border-color: var(--golden);
  color: var(--dark-golden);
  margin: 16px;
  cursor: not-allowed;
}
.non-dismissable {
  cursor: unset;
}
