.notes-tab {
  background-color: var(--white);
  padding: 10px;

  .page-content {
    padding: 0;

    .sub-button {
      margin: 0 0 0 auto;
      width: fit-content;
      position: relative;
      top: -57px;
      left: 10px;
      height: 0;

      .add-button {
        max-width: 110px;
        height: 32px;
      }
    }

    .form-section-head {
      .add-button {
        max-width: 110px;
      }
    }

    .c-form-section-body {
      padding: 0;

      &.c-form-section-transition-enter-done {
        max-height: none;
        padding: 0;
        overflow-y: visible;
      }
    }
    color: var(--text);
    .no-email {
      font-style: italic;
    }
  }
  .footer {
    .table-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 10px 0 0 0;
      background-color: var(--white);
      border-radius: 0 0 4px 4px;

      .page-numbers {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .page-num {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          border: 1px solid var(--grey-1);
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          user-select: none;
          cursor: pointer;
          margin: 0 0 0 5px;
        }

        .filler {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          border: 1px solid var(--grey-1);
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          user-select: none;
          cursor: pointer;
          margin: 0 0 0 5px;
        }

        .current {
          background-color: var(--grey-2);
        }
      }
    }
  }
}
