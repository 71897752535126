.package-form {
  .page-content {
    padding: 10px;

    h1 {
      font-size: 16px;
      color: var(--dark-grey);
    }

    .tab-content {
      padding: 10px;
      background-color: var(--white);

      .form-section {
        margin: 0 0 8px 0;
      }
    }

    .button-group {
      display: flex;
      padding: 10px 10px 20px 0;
      background-color: var(--white);
      flex-direction: row;
      justify-content: flex-end;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 90px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
