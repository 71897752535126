.id-container {
  position: relative;

  .id-button {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;
    border: none;
    padding: 0;
    width: 100%;

    .id-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      background-color: var(--dark-green);
      color: var(--white);
      border: 1px solid var(--dark-green);
      border-radius: 4px 0 0 4px;
      cursor: pointer;

      &.icon-only {
        background-color: var(--green);
        border: 1px solid var(--green);
        border-radius: 4px;
      }

      .id-arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: 12px;
        top: 7px;

        &.open {
          top: 12px !important;
        }
      }
    }

    .id-text {
      display: flex;
      position: relative;
      font-size: 13px;
      width: 100%;
      background-color: var(--green);
      color: var(--white);
      border: none;
      outline: none;
      border-radius: 0 4px 4px 0;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      padding: 0 20px 0 10px;

      .id-arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        top: 7px;

        &.open {
          top: 12px !important;
        }
      }
    }
  }

  .id-content {
    display: none;
    position: absolute;
    right: 0;
    top: 30px;
    background-color: var(--white);
    border-radius: 4px;
    width: fit-content;
    padding: 5px;

    button {
      font-size: 13px;
      width: 100%;
      min-width: 130px;
      border: none;
      outline: none;
      text-align: left;
      border-radius: 4px;
      color: var(--black);
      background-color: var(--white);
      height: 30px;
      cursor: pointer;

      &:hover {
        color: var(--white);
        background-color: var(--main-blue);
      }
    }
  }

  &:hover,
  &:focus {
    .id-content {
      display: block;
    }
  }

  &.no-hover {
    .id-content {
      display: block;
    }
  }
}
