.link-show-wrap {
  border: 1px solid var(--border-outline);
  border-radius: 4px;

  a {
    display: flex;
    flex: 1;
    padding: 6px 10px;
    min-height: 21px;
    align-self: stretch;
    text-decoration: none;
    outline: none;
    border: none;

    &.ls-placeholder {
      color: #999;
    }
  }
}
