.note-form {
  min-width: 500px;
  max-width: 600px;

  textarea {
    margin: 10px 0;
    resize: vertical;
  }

  .note-form-button-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;

    .checkbox {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .ib-wrap:not(:last-child) {
      margin: 0 10px 0 0;
    }

    .ib-wrap {
      flex: 0;
      width: 120px;
      height: 32px;

      .ib-icon {
        min-width: 32px;
      }

      .ib-button {
        font-weight: bold;
        font-size: 12px;
        max-width: 70px;
      }
    }
  }
}
