.vehicle-run-row {
  background-color: var(--main-background);

  .vrr-link {
    overflow-x: hidden;
    text-overflow: ellipsis;

    &.customer {
      margin: auto 0;
    }
  }

  .vrr-customer-ref {
    display: block !important;
    margin: auto 10px auto 0;
    height: fit-content;
  }

  .vrr-align-center {
    height: fit-content;
    margin: auto;
    display: block !important;

    .c-table-header-icon {
      background-color: var(--waste-yellow);
      color: var(--white);
      max-width: fit-content;
      padding: 3px 6px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--yellow);
      }
    }
  }

  .vrr-consignment-flag-container {
    display: flex;
    margin: auto 0 auto 0;
  }

  .ib-wrap {
    width: auto;
    height: 25px;
    margin-left: auto;
    margin-right: 10px;

    .ib-icon {
      height: 25px;
      min-width: 25px;
      background-color: var(--border-outline);
      border: 1px solid var(--border-outline);
      color: var(--text);

      &:hover {
        background-color: var(--grey-2);
        border: 1px solid var(--grey-2);
      }
    }
  }

  .vrr-status-container {
    display: flex;
    align-items: center;
    flex: 1;

    .status-icon {
      height: 10px;
      width: 10px;
      vertical-align: 0em;
      margin-right: 5px;

      &.active {
        color: var(--green);
      }

      &.pending {
        color: var(--grey-1);
      }

      &.finalised {
        color: var(--main-blue);
      }

      &.cancelled {
        color: var(--dark-red);
      }
    }

    .vrr-status-text {
      flex: 1;
    }
  }

  .vrr-actions {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    height: 28px;
    margin: auto;

    .warning-container {
      height: 23px;
      margin: auto 0 auto 0;
    }

    .warning-icon {
      padding: 6px;
      border-radius: 3px;
      color: var(--white);
      display: flex;
      justify-content: center;
      margin: auto 0 auto 0;
      margin-right: 15px !important;
      cursor: help;
    }
    .run-spacer {
      margin-right: 10px !important;
      width: 29px !important;
    }

    &.start {
      justify-content: flex-start;
    }

    .ib-wrap {
      flex: 0;
    }
  }

  @media (max-width: 1500px) {
    .desktop {
      display: none;
    }
    .mob {
      display: flex;
      font-size: 8px;
    }
    .vrr-actions {
      .run-spacer {
        width: 22px !important;
      }
    }
  }

  @media (min-width: 1501px) {
    .mob {
      display: none;
    }
    .desktop {
      display: flex;
    }
  }

  .vrr-direction-field {
    display: flex;
    gap: 5px;
    padding: 0 10px;
    margin: auto 0;
  }

  .vrr-job-field {
    display: flex;
    flex-direction: column;
    margin: auto 0 auto 0;
    width: 100%;
  }

  .vrr-hazards {
    min-width: 80px;
  }

  &.row-hidden {
    display: none;
  }

  &.highlighted {
    background-color: var(--border-outline) !important;
  }
}
