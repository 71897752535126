.draggable {
  cursor: grab;
}

.et-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .et-dropdown {
    min-width: 500px;
    padding: 20px 0;
    border-top: 1px solid var(--border-outline);
    border-bottom: 1px solid var(--border-outline);
  }

  .et-button-line {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 10px 0;

    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
