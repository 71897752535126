.vehicle-filter-bar {
  display: flex;
  min-height: 50px;
  color: var(--black);
  align-items: center;
  justify-content: space-between;
  background-color: var(--filter-bar);

  hr {
    border: 1px solid var(--grey-1);
    height: 32px;
    margin: 0 5px;
  }

  .vehicle-filter-section {
    display: flex;

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 10px;
    }

    .vehicle-filter-item {
      margin: 0 5px;
      display: flex;
      height: 32px;

      .sc-container {
        input {
          border: 2px var(--grey-1) solid;
          background-color: var(--white);

          &:checked {
            background-color: var(--green);
          }
        }
      }

      .dd-current {
        padding: 0 10px;
        height: 32px;

        svg {
          top: 5px;
        }
      }

      input {
        border-radius: 4px;
        border: 1px solid var(--grey-1);
        padding: 0 7px;
      }

      .ib-wrap {
        height: 32px;

        .ib-icon {
          min-width: 32px;
        }
      }
    }

    .selector-dropdown {
      width: 145pt;
      flex-grow: 1;
      .dd-main {
        flex-grow: inherit;
      }
    }

    .ib-button {
      margin: 0;
    }
  }
}
