.tab-content {
  .form-section {
    .form-section-head {
      .optional-buttons {
        display: flex;
        gap: 5px;

        .auto-line-button {
          max-width: 110px;
        }
      }
    }

    .form-section-body {
      .billing-table {
        font-weight: 300;
        width: 95%;
        border-collapse: collapse;
        .value {
          text-align: right;
        }
        th {
          padding-bottom: 5px;
          border-bottom: 1px solid var(--white);
        }
        tr {
          border-bottom: 1px solid var(--border-outline);
        }
        th:last-child,
        td:last-child {
          padding-left: 40px;
        }
        td {
          padding: 5px 0 5px 0;
          vertical-align: text-top;
          &:last-child {
            white-space: nowrap;
          }
        }

        th:nth-child(7),
        td:nth-child(7) {
          padding-left: 20px;
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--main-blue);
          &:visited {
            color: var(--main-blue);
          }
        }
      }
      .no-table {
        width: fit-content;
        padding: 4px;
        border-radius: 2px;
        background-color: var(--orange);
        color: var(--white);
      }
    }
  }
}
