.ii-wrap {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  user-select: none;
  cursor: pointer;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    background-color: var(--white);
    color: var(--icon-color);
    border: 1px solid var(--border-outline);
    border-radius: 4px 0 0 4px;
  }

  .icon-input {
    width: 100%;
    background-color: var(--white);
    color: var(--text);
    border: 1px solid var(--border-outline);
    border-left: none;
    outline: none;
    text-indent: 10px;
    border-radius: 0 4px 4px 0;
  }

  &:focus-within {
    .icon-container {
      border: 1px solid var(--grey-1);
      color: var(--text);
    }

    .icon-input {
      border: 1px solid var(--grey-1);
      border-left: none;
    }
  }
}
