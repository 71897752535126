.new-entity {
  .general {
    min-width: 500px;
  }
  .contact,
  .warehouse {
    min-width: 800px;
  }
}

.if-wrap input {
  border: 1px solid var(--border-outline) !important;
  border-radius: 4px !important;
  margin-right: 2px !important;
}

.if-wrap input:focus {
  border: 1px solid var(--dark-grey) !important;
}
