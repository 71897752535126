.vehicle-run-form {
  min-width: 1200px;
  max-width: 1400px;

  .form-section {
    margin: 0 0 20px 0;
  }

  .form-section:last-child {
    .form-section-body {
      min-height: 300px;
      overflow-y: scroll;
    }
  }
}