.c-form-section {
  margin: 0 0 8px 0;

  .c-form-section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 10px 20px;
    border: 1px solid #ececec;
    border-radius: 2px 2px 0 0;
    background: #fafafa;
    color: #555;
    user-select: none;
    overflow: hidden;
    font-weight: bold;
    cursor: pointer;

    .checkbox {
      .checkbox-text {
        color: var(--black);
        font-weight: 400;
      }
    }
    .c-form-section-title {
      display: flex;
      justify-content: space-between;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      svg {
        padding-right: 6px;
      }

      .c-form-section-title-split {
        font-weight: 700;
      }

      .c-form-optional {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 0;

        .c-form-pdf {
          padding: 4px 7px;
          font-size: 10px;
          border-radius: 3px;
          background-color: var(--stack-blue);
          color: var(--white);

          svg {
            padding-right: 0;
          }
        }
      }
    }
  }

  .c-form-section-body {
    flex-direction: column !important;
    padding: 20px;
    border-radius: 0 0 2px 2px;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    overflow-y: auto;

    table {
      width: 100%;

      thead {
        text-align: left;

        tr {
          th {
            font-size: 13px;
            color: var(--text);
          }
        }
      }

      tbody {
        tr {
          .small {
            width: 10%;
          }

          .medium {
            width: 30%;
          }

          .large {
            width: 40%;
          }
          td > input {
            width: calc(100% - 30px);
            color: var(--text);
            border: 1px solid var(--border-outline);
            border-radius: 4px;
            padding: 6px 10px;
            outline: none;

            &:focus {
              border: 1px solid var(--dark-grey);
            }
          }

          td > .vi-container {
            input {
              width: calc(100% - 20px);
              color: var(--text);
              border: 1px solid var(--border-outline);
              border-radius: 4px;
              padding: 6px 10px;
              outline: none;

              &:focus {
                border: 1px solid var(--dark-grey);
              }
            }
          }
        }
      }
    }

    .section-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      hr {
        width: 100%;
        border: none;
        background: var(--border-outline);
        height: 1px;
        margin: 10px 0;
      }

      .body-section {
        display: flex;
        flex-direction: column;
        flex: 1;

        h1 {
          font-size: 13px;
          color: var(--text);
          margin: 0 0 15px 0;
        }

        .text-line {
          margin: 5px 0 0 0;
          align-items: center;
          color: var(--text);
        }

        .icon-line {
          display: flex;
          align-items: center;
          margin: 0 0 10px 0;

          svg {
            width: 20px;
            font-size: 16px;
            color: var(--main-blue);
            margin: 0 5px 0 0;
          }

          em {
            color: var(--text);
          }

          .warning-icon {
            color: var(--yellow);
          }
        }
      }

      .button-section {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .button-line {
          display: flex;
          justify-content: flex-end;

          .ib-wrap {
            margin: 5px 0 0 10px;
          }
        }
      }
    }
  }

  .c-form-section-transition-enter {
    max-height: 0;
    padding: 0 20px;
  }

  .c-form-section-transition-enter-active {
    transition: max-height 200ms ease, padding 200ms ease;
    max-height: 250px;
    padding: 20px;
  }

  .c-form-section-transition-enter-done {
    max-height: 250px;
    padding: 20px;
  }

  .c-form-section-transition-exit {
    max-height: 250px;
    padding: 20px;
  }

  .c-form-section-transition-exit-active {
    transition: max-height 200ms, padding 200ms ease;
    max-height: 0;
    padding: 0 20px;
  }

  .c-form-section-transition-exit-done {
    max-height: 0;
    padding: 0 20px;
  }
}
