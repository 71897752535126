.folder-container {
  position: relative;
  background-color: var(--black);
  max-width: 245px;
}

.folder-content {
  padding: 0 0 0 10px;
  overflow: hidden;
}

.hover-content {
  max-height: 0;
  max-width: 190px;
  border-right: 1px solid var(--black);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 45px;
}

.freeze {
  max-height: 500px;
  opacity: 1;
}

/* Folder open / close animation */
.folder-transition-enter {
  opacity: 0;
  max-height: 0;
}

.folder-transition-enter-active {
  /* This staggers the animation */
  @for $i from 1 to 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 300ms;
    }
  }

  opacity: 1;
  max-height: 500px;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.folder-transition-enter-done {
  opacity: 1;
  max-height: 500px;
}

.folder-transition-exit {
  opacity: 1;
  max-height: 500px;
}

.folder-transition-exit-active {
  /* This staggers the animation */
  @for $i from 1 to 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 300ms;
    }
  }

  opacity: 0;
  max-height: 0;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.folder-transition-exit-done {
  opacity: 0;
  max-height: 0;
}
