.tbm-table {
  display: flex;
  gap: 1px;
  border-collapse: collapse;

  .tbm-table-col {
    width: 85px;
    border-collapse: collapse;

    .tbm-table-header {
      width: 80px;
      border: 1px solid var(--border-outline);
      border-collapse: collapse;
      height: 22px;
      padding: 0 2px;
      cursor: default;
      background-color: var(--main-background);
      display: flex;

      button {
        margin-left: auto;
        background: none;
        border: 0px;
        cursor: pointer;

        svg {
          color: var(--red);
        }

        &:hover {
          svg {
            color: var(--dark-red);
          }
        }
      }
    }
    .tbm-table-value {
      width: 80px;
      height: 20px;
      border: 1px solid var(--border-outline);
      border-collapse: collapse;
    }

    &.first {
      width: 120px;

      .tbm-table-header {
        width: 115px;
      }
    }
  }
}
