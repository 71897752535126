.cancel-job-modal {
  min-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .cancel-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .cancel-title {
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: 600;
      &:after {
        content: ' *';
      }
    }
    .cancel-reason {
      width: 94%;
      height: 100px;
      outline: none;
      resize: none;
      border: 1px solid var(--border-outline);
      padding: 13px 0 0 16px;
      &:focus {
        border: 1px solid var(--dark-grey);
      }
    }
  }
  .af-button-line {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0 18px 0;
    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
