.selector-dropdown {
  position: relative;

  .selector-value {
    display: flex;
    align-items: center;
    min-width: fit-content;
    width: 110px;
    height: 25px;
    border: 1px solid var(--dark-blue);
    border-radius: 4px;
    padding: 0 10px 0 10px;
    white-space: nowrap;
    font-size: 13px;

    svg {
      position: absolute;
      right: 10px;
      top: 4px;
    }
  }

  .selector-options {
    display: none;
    position: absolute;
    padding: 5px;
    background-color: var(--white);
    right: 0;
    flex-direction: column;
    border-radius: 4px;
    z-index: 4;
    min-width: fit-content;

    .selector-option {
      padding: 6px;
      width: 140px;
      color: var(--text);
      cursor: pointer;
      border-radius: 4px;

      &.selected,
      &:hover {
        color: var(--white) !important;
        background-color: var(--main-blue);
      }
    }
  }
}

.selector-dropdown:hover {
  .selector-options {
    display: flex;
  }
}
