.show-field {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 200px calc(100% - 200px);
  align-items: center;
}

.show-field-label {
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  color: #555;
}

.show-field-value {
  height: 40px;
  border-radius: 4px;
  padding: 0 10px;

  border: 1px solid var(--border-outline);
  background: var(--white);
  color: var(--text);
}
