.table {
  .table-top {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: var(--main-blue);
    color: var(--white);
    border-radius: 4px 4px 0 0;

    .section {
      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        margin: 0;
      }

      input {
        border-radius: 4px;
        border: none;
        outline: none;
        text-indent: 5px;
        height: 25px;
        margin: 0 0 0 10px;
      }
    }
  }

  .section > * {
    margin: 0 0 0 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th {
          position: relative;
          height: 30px;
          text-align: left;
          padding: 0 10px;
          background-color: var(--dark-blue);
          color: var(--white);
          border: 1px solid var(--dark-blue);
          user-select: none;
          cursor: pointer;
          min-width: 78px;
          font-weight: normal;

          &.tiny {
            min-width: 60px;
          }

          &.x-tiny {
            min-width: 50px;
          }

          svg {
            position: absolute;
            right: 10px;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: var(--alt-grey);

        .tiny {
          width: 2%;
        }

        td {
          padding: 0 10px;
          height: 40px;
          border: 1px solid var(--border-outline);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 170px;

          a {
            text-decoration: none;
          }

          .td-buttons {
            display: flex;
            width: 100%;

            .ib-wrap {
              margin: 0 0 0 5px;
            }
          }
        }
      }

      tr:hover {
        background-color: var(--main-background);
      }

      .coloured {
        background-color: var(--main-background);
      }
    }
  }

  .table-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 0 0;
    padding: 0 10px;
    height: 50px;
    background-color: var(--white);
    border-radius: 0 0 4px 4px;

    .page-numbers {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .page-num {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        border: 1px solid var(--grey-1);
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        user-select: none;
        cursor: pointer;
        margin: 0 0 0 5px;
      }

      .filler {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        border: 1px solid var(--grey-1);
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        user-select: none;
        cursor: pointer;
        margin: 0 0 0 5px;
      }

      .current {
        background-color: var(--grey-2);
      }
    }
  }
}
