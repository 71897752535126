.direction-label {
  border-radius: 3px;
  color: var(--white);
  padding: 2px 7px;
  width: 25px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.other {
  background-color: var(--grey-1);
}

.deliver {
  background-color: var(--green);
}

.collect {
  background-color: var(--main-blue);
}
