.vrs-routes {
  padding: 0 0 10px 0 !important;

  .timeline {
    position: relative;
    // margin: 20px 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 75px;
      bottom: 100px;
      z-index: 2;
      width: 4px;
      top: 80px;
      margin-left: -2px;
      background-color: var(--main-blue);
    }

    .no-wp-message {
      padding: 10px;
      color: var(--text);
    }

    .vrsr-entry:nth-child(even) {
      background-color: #f7f7f7;
    }
  }
}
