.vi-container {
  display: flex;

  .vi-input-container {
    position: relative;
    flex: 3;
    padding: 0;
    margin: 0;
    border-radius: 4px 0 0 4px;

    .vi-input {
      width: 100%;
      border-radius: 4px 0 0 4px;

      &:read-only,
      &:disabled {
        appearance: textfield;
      }
    }

    svg {
      position: absolute;
      right: 10px;
    }

    svg:first-of-type {
      top: 10px;
    }

    svg:last-of-type {
      top: 13px;
    }
  }

  .vi-type {
    display: flex;
    flex: 2;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: 32px;
    background-color: var(--grey-2);
    color: var(--black);
    font-size: 13px;
    font-weight: 600;
    border-radius: 0 4px 4px 0;
    cursor: default;
  }
}

.reverse {
  flex-direction: row-reverse;

  .vi-input-container > .vi-input {
    width: 95%;
    border-radius: 0 4px 4px 0 !important;

    &input {
      border-radius: 0 4px 4px 0 !important;
    }
  }

  .vi-type {
    border-radius: 4px 0 0 4px;
  }
}
