.all-users {
  .page-content {
    padding: 10px;
    td {
      width: 20%;
      word-break: normal;

      &.actions {
        max-width: none;
      }
    }

    .small {
      width: 10%;
    }
    .large {
      width: 30%;
    }
  }
}
