.model-icon {
  cursor: pointer;
  color: var(--main-blue);
}
.model-name {
  cursor: pointer;
  padding: 0 4px;
  margin-left: 5px;
  border-radius: 4px;

  &:hover {
    color: var(--dark-blue);
    background-color: var(--light-blue);
  }
}

.panel-tree-submodels {
  padding-left: 10px;
}
