.button-group {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 20px 10px;
  background-color: var(--white);
  flex-direction: row;

  .ib-wrap {
    margin: 0 10px 0 0;
    height: 30px;
    max-width: 90px;

    .ib-icon {
      min-width: 30px;
    }

    .ib-button {
      font-size: 12px;
    }
  }
}
.link-input-wrap {
  min-width: 500px;

  hr {
    width: 100%;
    border: none;
    background: var(--border-outline);
    height: 1px;
    margin: 10px 0;
  }
}

.if-wrap input {
  border: 1px solid var(--border-outline) !important;
  border-radius: 4px !important;
  margin-right: 2px !important;
}

.if-wrap input:focus {
  border: 1px solid var(--dark-grey) !important;
}
