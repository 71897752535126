.tariff-band-management {
  .page-content {
    padding: 10px;

    .tab-content {
      background-color: var(--white);
      padding: 10px;

      h2 {
        color: var(--text);
        margin-bottom: 5px;
      }

      .tbm-row-add {
        display: flex;
        gap: 15px;

        input {
          color: var(--text);
          border: 1px solid var(--border-outline);
          border-radius: 4px;
          padding: 6px 10px;
          outline: none;

          &:focus {
            border: 1px solid var(--dark-grey);
          }
        }

        .ib-wrap {
          max-width: 100px;
          height: 32px;

          .ib-icon {
            height: 30px;
            min-width: 32px;
          }
        }

        .save-button {
          margin-left: auto;
          margin-right: 10px;
        }
      }
    }

    h1 {
      color: var(--text);
      font-size: 16px;
    }
  }
}
