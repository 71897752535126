.profitab {
  .tab-group {
    .body {
      .tab-content {
        .table {
          margin: 10px 0 0 0;
        }
      }
    }
  }
}
