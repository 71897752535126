.finalise-job-modal {
  min-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 5px 10px 5px;
  margin: 20px 0 10px 0;
  .finalise-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    .job-id {
      font-weight: 500;
      margin: 10px;
    }
  }
  .af-button-line {
    display: flex;
    justify-content: center;
    margin: 24px 0 18px 0;
    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
