/* This needs to be extracted into proper form components in a later ticket */

.basic-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);

  .row {
    display: flex;
    flex-direction: row;
    margin: 0 0 5px 0;
  }

  .submit-row {
    display: flex;
    flex-direction: row;
    margin: 15px 0 0 0;
  }
}
