.ti-wrap {
  .ti-tags {
    max-height: 203px;
    overflow: auto;

    .ti-tag {
      border-radius: 3px;
      background-color: var(--grey-3);
      color: var(--white);
      padding: 3px 7px;
      cursor: pointer;
      border: none;
      margin: 0 5px 5px 0;

      svg {
        margin: 0 0 0 3px;
      }

      &:hover {
        filter: brightness(85%);
      }

      &.new {
        background-color: var(--grey-1);
        color: var(--text);
      }
    }
  }
}
