.vrs-wrap {
  display: flex;
  position: relative;

  .vrs-status {
    margin: auto;
    margin-right: 10px;
    padding: 3px 10px;
    border-radius: 3px;
    color: var(--white);
    font-size: 13px;
    font-weight: normal;
    min-width: 69px;
    text-align: center;
    cursor: pointer;

    &.vrs-pending {
      background-color: var(--green);
      border: 1px solid var(--green);
    }

    &.vrs-in-progress {
      background-color: var(--yellow);
      border: 1px solid var(--yellow);
    }

    &.vrs-completed {
      background-color: var(--main-blue);
      border: 1px solid var(--main-blue);
    }
  }

  .vrs-content {
    display: none;
  }

  .vrs-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border: 1px solid var(--border-outline);
    top: 26px;
    color: var(--text);
    font-size: 13px;
    font-weight: normal;

    .vrs-option {
      padding: 5px 10px;
      width: 100px;
      border: none;
      background-color: var(--white);
      text-align: left;
      cursor: pointer;

      &:hover {
        background-color: var(--grey-1);
      }
    }
  }
}
