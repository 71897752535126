.rt-editor {
  .DraftEditor-root {
    padding: 10px;
    border: 1px solid var(--grey-1);
    border-top: none;

    .DraftEditor-editorContainer > div > div {
      min-height: 300px;
    }
  }
}
