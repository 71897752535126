.field-list {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 49% 49%;
  min-height: 32px;

  .vi-input-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .vi-type {
    min-width: 40px;
  }
}

.field-list + .field-list {
  margin-top: 10px;
}
