.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  background-color: var(--main-background);

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    min-height: 200px;
    min-width: 100px;
    width: 60vw;
    max-width: 500px;
    padding: 20px 100px 45px 100px;

    img {
      max-width: 250px;
      margin: 10px 0;
    }

    .login-logo {
      height: 80px;
      width: 180px;
    }

    .login-form {
      min-width: 400px;
    }
  }

  .development {
    border: 2px solid var(--development-color);
    border-radius: 5px;
  }
}
