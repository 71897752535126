.cm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;

  .cm-question {
    margin: 0 0 10px 0;
  }

  .cm-subtext {
    margin: 0 0 20px 0;
    font-weight: bold;
  }

  .cm-button-line {
    display: flex;

    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
