.edit-vehicle-run {
  .page-content {
    padding: 10px;

    h1 {
      color: var(--text);
      font-size: 16px;
    }

    .tab-content {
      background-color: var(--white);
      padding: 10px;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0 20px 10px;
      background-color: var(--white);
      flex-direction: row;
    }
  }
}
