html,
body {
  height: 100%;

  #root {
    height: inherit;
  }
}

.vehicle-run-drag-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 20%;
  z-index: -1; //hide this from view to the user, as its just used as we need dragImage on DOM
  background-color: var(--main-background);
  display: flex;

  .warning-icon {
    padding: 5px !important;
    margin: auto;
    margin-right: 10px !important;
    background-color: var(--light-golden);
    color: var(--waste-yellow) !important;
  }

  .hazards {
    width: fit-content;
    background-color: var(--border-red);
    color: var(--dark-red);
    padding: 2px 5px;
    border-radius: 3px;
    height: 18px;
    margin: auto 0 auto 0;

    svg {
      vertical-align: 0em;
      height: 0.75em;
    }
  }

  .hover-cell-info {
    display: none !important;
  }

  .row {
    color: var(--text);
    height: 40px;
    background-color: var(--white) !important;
    border-bottom: 1px solid var(--border-outline);
    display: flex;

    .cell {
      display: flex;
      color: var(--text);
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;

      a,
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
      }
    }

    &.highlighted {
      background-color: var(--main-background) !important;
    }
  }
}

.wrap {
  height: inherit;

  .json-content {
    height: 400px;
    width: 600px;
    padding: 10px;
    margin: 10px 0;
    background-color: #1a1c1d;
    color: #d0d0d0;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .run-loading {
    width: 100%;
    max-height: 50px;
    margin-top: 10px;
  }

  @media (max-width: 1500px) {
    .vrr-collection-shrink {
      width: 55px !important;
    }

    .c-table-headers {
      .header {
        font-size: 8px !important;
        margin-right: 5px !important;
      }
    }

    .cell {
      margin-right: 7px !important;
    }
  }

  .c-table-body {
    .cell {
      a {
        text-decoration: none;
      }
    }
  }

  .c-table-section {
    margin: 0;
  }

  .c-table-header-section {
    .id-content {
      hr {
        border: none;
        border-bottom: 1px solid var(--border-outline);
      }

      button {
        min-width: 170px;
      }
    }
  }

  .c-table-vehicle-info {
    display: flex;
    justify-content: space-between;

    .vehicle-info-main {
      display: flex;
      margin-right: 60px;

      div {
        margin: auto;
        margin-right: 10px;
        font-weight: normal;

        &:first-child {
          font-weight: bold;
        }
      }
    }

    .vehicle-info-status {
      display: flex;
      justify-content: space-between;

      .subcontracted {
        border: 1px solid var(--white);
        margin: auto;
        margin-right: 10px;
        padding: 3px 10px;
        border-radius: 3px;
        color: var(--white);
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  .restrictions {
    color: var(--restriction-blue);
  }

  .do-not-stack {
    color: var(--stack-blue);
  }

  .waste {
    color: var(--waste-yellow);
  }

  .foodstuffs {
    color: var(--food-green);
  }

  .overlength {
    color: var(--overlength-purple);
  }

  .tall-package {
    color: var(--tall-pink);
  }

  .tail-lift {
    color: var(--tail-brown);
  }

  .timed {
    color: var(--timed-red);
  }

  .connection {
    color: var(--dark-red);
  }

  .hazards {
    width: fit-content;
    background-color: var(--border-red);
    color: var(--dark-red);
    padding: 2px 5px;
    border-radius: 3px;
    height: 18px;
    margin: auto 0 auto 0;

    svg {
      vertical-align: 0em;
      height: 0.75em;
    }

    &:hover {
      cursor: help;

      .hazards-hover {
        display: block;
      }
    }
  }

  .hover-cell {
    position: relative;
    overflow: initial !important;

    .hover-cell-info {
      display: none;
      position: absolute;
      width: 300px;
      color: var(--text);
      margin: 0;
      z-index: 1;
      word-wrap: normal;
      white-space: normal;
    }

    &:hover {
      cursor: help;

      .hover-cell-info {
        display: block;
        border: 1px solid;
        border-collapse: collapse;
        top: 32px;

        .top {
          border-bottom: 1px solid;
          padding: 10px 20px;
          background-color: var(--grey-1);
          font-weight: bold;
          margin: 0;
        }

        .main {
          padding: 10px 20px;
          margin: 0;
          background-color: var(--white);
        }
        &.hazards-info {
          right: 7px !important;
        }

        &.warning {
          right: 17px !important;
          border-color: var(--tail-brown);
          max-width: 450px;
          z-index: 1;
          top: 25px;

          .top {
            background-color: var(--light-golden);
            color: var(--waste-yellow);
            border-color: var(--tail-brown);
          }
        }
      }
    }

    &.subcontracted {
      cursor: not-allowed;
    }
  }

  .vr-header {
    position: fixed;
    top: 0;
    z-index: 4;
    height: 100px;
    width: 100%;
  }

  .main-container {
    height: 100%;
    position: sticky;

    .content-container {
      position: relative;
      top: 100px;
      height: calc(100% - 100px);
      background-color: var(--main-background);

      .content {
        overflow-y: scroll;
        height: 100%;
      }
    }
  }

  &.sub {
    .vr-header {
      height: 50px;
    }

    .content-container {
      top: 50px;
      height: calc(100% - 50px);
    }
  }
}
