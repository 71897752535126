.vehicle-run-footer {
  display: flex;
  gap: 15px;

  .vrf-job-count {
    margin-left: 50px;
  }

  .vrf-direction-section {
    display: flex;
    gap: 5px;

    .info-text {
      &::after {
        content: ' /';
      }

      &:last-of-type {
        &::after {
          content: '';
        }
      }
    }
  }
}
