.tab-group {
  .body {
    .tab-content {
      padding: 10px;

      hr {
        width: 100%;
        border: none;
        background: var(--border-outline);
        height: 1px;
        margin: 10px 0;
      }

      .button-line {
        display: flex;
        flex-direction: row;
        height: 37px;

        .sc-container {
          margin: 0 20px 0 0;
        }
      }
      .section-title {
        margin: 16px 0 0 0;
        font-size: 13px;
        font-weight: 600;
        color: #555;
      }
    }
  }
}

.tab-group > .tabs {
  display: flex;
  border-bottom: 2px solid var(--border-outline);
}

.tab-group > .tabs > .tab {
  cursor: pointer;
  margin: 0 2px 0 0;

  border: none;
  padding: 8px 12px;

  background-color: var(--border-outline);
  color: var(--text);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: -1px;
  border-bottom: 2px solid var(--border-outline);
}

.tab-group > .tabs > .tab:focus,
.tab-group > .tabs > .tab:hover {
  background-color: var(--hover-background);
  border-bottom-color: var(--hover-border);
  color: var(--text);
}

.tab-group > .tabs > .tab.active {
  background: var(--main-blue);
  color: var(--white);
  border-bottom: 2px solid var(--dark-blue);
}
