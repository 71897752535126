.nopage-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  text-align: center;
  margin: 50px auto;

  img {
    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }
}
