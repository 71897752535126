.form-section {
  background: var(--white);

  .form-section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 10px 20px;
    border: 1px solid #ececec;
    border-radius: 2px 2px 0 0;
    background: #fafafa;
    color: #555;
    font-weight: bold;
    user-select: none;
    overflow: hidden;

    .optionalElement {
      display: flex;
      > .show-field {
        margin-left: 16px;
        grid-template-columns: auto calc(100% - 200px);
      }
      > .checkbox {
        margin-left: 10px;
      }
    }

    .double-head-checkbox {
      display: flex;

      .checkbox {
        &:not(:last-child) {
          margin: 0 10px 0 0;
        }
      }
    }

    .multi-checkboxes {
      display: flex;

      .show-field {
        display: flex;

        &:not(:last-child) {
          margin: 0 20px 0 0;
        }
      }
    }

    div > .with-status {
      display: flex;
      align-items: center;

      .status {
        margin: 0 0 0 10px;
        padding: 3px 10px;
        border-radius: 3px;
        color: var(--white);
        font-size: 13px;
        font-weight: normal;
        min-width: 69px;
        text-align: center;

        &.pending {
          background-color: var(--green);
          border: 1px solid var(--green);
        }

        &.in_progress {
          background-color: var(--yellow);
          border: 1px solid var(--yellow);
        }

        &.complete {
          background-color: var(--main-blue);
          border: 1px solid var(--main-blue);
        }
      }

      .vrd-separator {
        margin: 0 10px;
      }
    }

    &.draggable {
      cursor: grab;
    }

    .checkbox {
      .checkbox-text {
        color: var(--black);
        font-weight: 400;
      }
    }

    .ib-wrap {
      height: 30px;

      .ib-icon {
        min-width: 30px;
      }

      .ib-button {
        width: 100px;
        font-weight: 550;
        font-size: 12px;
      }
    }

    .optionalElement {
      .ib-button {
        width: fit-content;
      }
    }
  }

  .form-section-body {
    padding: 20px;
    border-radius: 0 0 2px 2px;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    &.nopad {
      padding: 0px;
    }

    &.large {
      min-width: 60vw;
    }

    .body-section {
      display: flex;
      flex-direction: column;

      .icon-line {
        display: flex;
        align-items: center;
        margin: 0 0 10px 0;

        svg {
          width: 20px;
          font-size: 16px;
          color: var(--main-blue);
          margin: 0 5px 0 0;
        }
      }
    }

    .button-section {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .button-line {
        display: flex;

        .ib-wrap {
          margin: 5px 10px 0 0;
        }
      }
    }

    .checkbox-line {
      display: grid;
      grid-template-columns: 200px 200px 200px 200px 200px;
      margin: 0 0 10px 0;
      justify-content: space-evenly;
    }

    .space-fields {
      .form-field {
        margin: 0 0 10px 0;
      }
    }

    table {
      width: 100%;

      thead {
        text-align: left;

        tr {
          th {
            font-size: 13px;
            color: var(--text);
          }
        }
      }

      tbody {
        tr.lined > td {
          border-top: 1px dotted var(--grey-1);
        }

        tr {
          .tiny {
            width: 1%;
          }

          .small {
            width: 15%;
          }

          .medium {
            width: 25%;
          }

          .large {
            width: 30%;
          }

          .bold {
            font-weight: bold;
            color: var(--text);
          }

          td > input {
            width: calc(100% - 30px);
            color: var(--text);
            border: 1px solid var(--border-outline);
            border-radius: 4px;
            padding: 6px 10px;
            outline: none;

            &:focus {
              border: 1px solid var(--dark-grey);
            }
          }

          td > a {
            text-decoration: none;
          }

          td > .vi-container {
            input {
              width: calc(100% - 20px);
              color: var(--text);
              border: 1px solid var(--border-outline);
              padding: 6px 10px;
              outline: none;

              &:focus {
                border: 1px solid var(--dark-grey);
              }
            }
          }

          td > .show-field {
            display: flex;
            justify-content: center;
            max-width: 50px;
          }

          .fs-table-cross {
            height: 32px;
            width: 32px;
            background-color: var(--red);
            color: var(--white);
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              background-color: var(--light-red);
            }
          }
        }
      }

      .disableable {
        td > .vi-container > .vi-input-container > .vi-input:read-only {
          background-color: var(--grey-2);
          cursor: not-allowed;
        }

        td > input:read-only {
          background-color: var(--grey-2);
          cursor: not-allowed;
        }
      }
    }
  }
}
