tr {
  .fit {
    width: 0;
  }

  .vrr-bool {
    width: 90px;

    .vrr-check {
      path {
        fill: var(--text);
      }
    }

    .vrr-cross {
      path {
        fill: var(--text);
      }
    }
  }
}
