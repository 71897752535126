.email-template-show {
  .page-content {
    .template-title {
      color: var(--dark-grey);
      font-weight: 700;
      font-size: 16px;
      .latter-title {
        font-weight: 500;
      }
    }
    padding: 10px;

    .tab-content {
      background-color: var(--white);
      padding: 10px;
    }
  }
}
