.dp-container {
  display: flex;
  height: 32px;

  .dp-input {
    display: block;
    flex: 1;
    border-radius: 0 !important;
  }

  .dp-icon {
    height: 32px;
    max-width: 32px;
    min-width: 32px;
    background-color: var(--grey-1);
    color: var(--text);
    border: none;
    z-index: 1;

    &:hover {
      background-color: var(--hover-background);
    }
  }

  .dp-icon-right {
    border-radius: 0 4px 4px 0;
  }

  .dp-icon-left {
    border-radius: 4px 0 0 4px;
  }
}
