.show-vehicle-run {
  .page-content {
    padding: 10px;

    h1 {
      color: var(--text);
      font-size: 16px;
    }

    .tab-content {
      background-color: var(--white);
      padding: 10px;
    }
  }
}
