.toast-container {
  height: 100%;
  position: absolute;
  right: 5px;
  overflow-y: hidden;

  .error-message-container {
    position: relative;
    right: 0;
    margin-bottom: 10px;
  }
}
