.vrs-financials {
  .form-section {
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }

    .right {
      text-align: right;
    }
  }
}
