.dd-main {
  position: relative;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
  max-width: 100%;

  .dd-text {
    width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dd-current {
    position: relative;
    color: var(--text);
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    padding: 6px 10px;
    outline: none;
    height: 32px;
    background-color: var(--white);
    width: 100%;
    text-align: left;
    white-space: nowrap;

    svg {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .clear {
      top: 8px;
      right: 25px;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid var(--dark-grey);
    }
    &:disabled {
      background-color: var(--grey-2);
      cursor: not-allowed;
    }
  }
}

#portal-root {
  .dd-content {
    position: absolute;
    border-right: 1px solid var(--border-outline);
    border-left: 1px solid var(--border-outline);
    border-bottom: 1px solid var(--border-outline);
    border-radius: 0 0 4px 4px;
    background: var(--white);
    z-index: 10000;

    .dd-search {
      display: flex;
      position: relative;
      margin: 5px 0;
      padding: 0 10px;

      .dd-input {
        width: 100%;
        color: var(--text);
        border: 1px solid var(--border-outline);
        border-radius: 4px;
        padding: 10px;
        outline: none;
      }

      svg {
        position: absolute;
        right: 21px;
        top: 11px;
        color: var(--text);
      }
    }

    .dd-options {
      max-width: 100%;
      max-height: 210px;
      overflow-y: auto;
      overflow-x: hidden;

      .dd-option {
        display: flex;
        align-items: center;
        padding: 0 0 0 10px;
        height: 30px;
        color: var(--text);
        cursor: pointer;
        background-color: var(--white);

        &:hover {
          background-color: var(--grey-2);
        }
      }

      .dd-button {
        display: flex;
        text-align: left;
        width: 100%;
        align-items: center;
        padding: 0 0 0 10px;
        height: 30px;
        border: none;
        outline: none;
        background-color: var(--white);
        white-space: nowrap;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: var(--grey-2);
        }
      }

      .dd-message {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 0 0 10px;
        font-style: italic;
        color: var(--text);
      }
    }

    .dd-search:focus-within ~ .dd-options {
      .dd-button:first-child {
        background-color: var(--grey-2);
      }
    }
  }
}

.dd-main:focus {
  .dd-current {
    border: 1px solid var(--dark-grey) !important;
  }
}

.dd-main:disabled {
  user-select: none;

  .dd-current {
    background-color: var(--grey-2);
  }
}
