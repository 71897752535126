.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: var(--white);

  .input-file-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    border: 1px solid var(--border-outline);
    border-right: 0;
    border-radius: 3px 0 0 3px;
    font-size: 13px;

    .input-file-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      height: 100%;
      width: 270px;

      .input-file-icon {
        display: flex;
        align-items: center;
        height: 100%;
        vertical-align: middle;
        padding: 0px 8px 0 8px;
        border-right: 1px solid var(--border-outline);
      }

      .input-file-text {
        margin-left: 13px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }

  input#file {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  input#file::file-selector-button {
    display: none;
  }

  .upload-btn {
    width: 73px;
    height: 34px;
    background-color: var(--main-blue);
    border: 1px solid var(--main-blue);
    border-radius: 0 3px 3px 0;
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      background-color: var(--dark-grey);
      border: 1px solid var(--dark-grey);
    }
  }
}
