.contact-form {
  min-width: 800px;

  .contact-form-button-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ib-wrap:not(:last-child) {
      margin: 0 10px 0 0;
    }

    .ib-wrap {
      flex: 0;
      width: 100px;
      height: 32px;

      .ib-icon {
        min-width: 32px;
      }

      .ib-button {
        font-weight: bold;
        font-size: 12px;
        max-width: 60px;
      }
    }
  }
}
