.note {
  display: grid;
  grid-template-columns: 80% 20%;
  padding: 10px;
  min-height: 32px;

  p {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 100%;
  }

  .note-button-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ib-wrap:not(:last-child) {
      margin: 0 10px 0 0;
    }

    .ib-wrap {
      flex: 0;
      width: 120px;
      height: 32px;

      .ib-icon {
        min-width: 32px;
      }

      .ib-button {
        font-weight: bold;
        font-size: 12px;
        max-width: 60px;
      }
    }
  }
}
