.otl-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  background: var(--alt-background);
  border: 1px solid var(--border-outline);
  padding: 5px;
  margin: 0 0 5px 0;

  .otl-time {
    color: var(--text);
    margin: 0 0 0 5px;
  }

  .otl-buttons {
    display: flex;
    .ib-wrap {
      margin: 0 0 0 5px;
    }
  }
}
