.lb-container {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  height: 40px;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  background-color: var(--black);
  color: var(--dark-grey);

  &:hover {
    color: var(--grey-1);
  }

  .lb-icon {
    width: 40px;
  }

  .lb-text {
    text-align: left;
    width: 170px;
    padding: 0 40px 0 0;
    white-space: nowrap;
    overflow: hidden;
  }

  /* Sets arrow position on LinkBar */
  .lb-arrow {
    position: absolute;
    right: 10px;
  }
}

/* Animation: Arrow rotating when folder opened / closed */
.arrow-transition-enter {
  transform: rotate(0deg);
}

.arrow-transition-enter-active {
  transform: rotate(90deg);
  transition: transform 200ms ease-in-out;
}

.arrow-transition-enter-done {
  transform: rotate(90deg);
}

.arrow-transition-exit {
  transform: rotate(90deg);
}

.arrow-transition-exit-active {
  transform: rotate(0deg);
  transition: transform 200ms ease-in-out;
}

.arrow-transition-exit-done {
  transform: rotate(0deg);
}
