.c-table-section {
  .warning-cell {
    display: flex;
    position: relative;
  }

  .warning-icon {
    padding: 5px !important;
    margin: auto;
    margin-right: 10px !important;
    background-color: var(--light-golden);
    color: var(--waste-yellow) !important;
  }

  .run-icon {
    padding: 3px 5px !important;
    margin: auto;
    margin-right: 10px !important;
    background-color: var(--main-blue);
    border: 1px solid var(--main-blue);
    border-radius: 4px;
    color: var(--white) !important;
    text-decoration: none;
  }

  .hover-cell-info {
    white-space: normal;

    .main {
      .warning-info {
        margin-bottom: 6px;
        font-weight: normal;
      }
    }

    &.warning {
      right: 30% !important;
      top: 27px;
      width: 450px;
    }
  }

  .no-icon {
    margin-right: 10px !important;
    width: 4px !important;
  }

  .vehicle-info-status {
    display: flex;
    justify-content: space-between;

    .subcontracted {
      border: 1px solid var(--white);
      margin: auto;
      margin-right: 10px;
      padding: 3px 10px;
      border-radius: 3px;
      color: var(--white);
      font-size: 13px;
      font-weight: normal;
    }
  }
}
