.all-package-types {
  .page-content {
    padding: 10px;
  }
  td {
    width: 20%;
  }
  .small {
    width: 10%;
  }
}
