.booking-notes-tab {
  padding: 20px;
  background-color: var(--white);

  .tab-group {
    .body {
      border: 1px solid var(--border-outline);
      border-top: none;
    }
  }
}
