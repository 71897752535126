.left-bar {
  background-color: var(--black);
  color: var(--grey-2);
  border-right: 1px solid var(--black);
  a {
    text-decoration: none;
  }

  .lb-user {
    display: flex;
    height: 75px;
    overflow: hidden;

    .lbu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;

      .lbu-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: var(--grey-2);
        border-radius: 100%;

        svg {
          font-size: 20px;
          color: var(--dark-grey);
        }
      }
    }

    .lbu-options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--dark-grey);
      width: calc(100% - 75px);

      .lbu-name {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
      }

      .lbu-buttons {
        display: flex;

        .lbu-button {
          background: none;
          border: none;
          outline: none;
          color: var(--dark-grey);
          padding: 5px 10px 0 5px;
        }

        .lbu-button:hover,
        .lbu-button:focus {
          color: var(--white);
          cursor: pointer;
        }
      }
    }
  }
}

/*
  Handles the animation of opening and closing
  Please note that the enter-done and exit-done stages will
    house important changes that must persist
*/
.left-bar-transition-enter {
  max-width: 245px;
  padding: 0;

  .lb-user {
    height: 75px;
  }
}

.left-bar-transition-enter-active {
  max-width: 55px;
  transition: max-width 300ms ease-in-out;

  /* Add padding to contain the arrow */
  & > .folder-container > .lb-container {
    padding: 0 15px 0 0;
    transition: padding 300ms ease-in-out;
  }

  .lb-user {
    height: 0;
    transition: height 300ms ease-in-out;
  }
}

/*
  If you want to edit anything about the floating content dropdown
  it's all below here in this big chungus method
*/
.left-bar-transition-enter-done {
  max-width: 55px;

  /* Add padding to contain the arrow */
  & > .folder-container > .lb-container {
    padding: 0 15px 0 0;
  }

  /* Hide the text when minimised */
  & > .folder-container > .lb-container > .lb-text {
    display: none;
  }

  /* Hide the user when minimised */
  & > .lb-user {
    display: none;
  }

  & > .folder-container:hover > .hover-content {
    max-height: 500px;
    opacity: 1;
    transition: max-height 300ms ease, opacity 300ms ease;
    z-index: 100;
  }
}

.left-bar-transition-exit {
  max-width: 55px;

  /* Add padding to contain the arrow */
  & > .folder-container > .lb-container {
    padding: 0 15px 0 0;
  }

  /* Show user data falling from above */
  .lb-user {
    height: 0;
  }
}

.left-bar-transition-exit-active {
  max-width: 245px;
  transition: max-width 300ms ease-in-out, padding 300ms ease-in-out;

  /* Remove padding for arrow */
  & > .folder-container > .lb-container {
    padding: 0 0 0 0;
    transition: padding 300ms ease-in-out;
  }

  /* Show user data falling from above */
  .lb-user {
    height: 75px;
    transition: height 300ms ease-in-out;
  }
}

.left-bar-transition-exit-done {
  max-width: 245px;

  /* Remove padding for arrow */
  & > .folder-container > .lb-container {
    padding: 0 0 0 0;
  }

  /* Show user data falling from above */
  .lb-user {
    height: 75px;
  }
}
