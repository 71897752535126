.report-show {
  .page-content {
    padding: 10px;

    h1 {
      font-size: 15px;
      color: var(--dark-grey);
    }
  }
}
