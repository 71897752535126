.ecm-wrap {
  display: block;
  width: 50vw;

  .form-section {
    .form-section-body {
      .tab-group {
        .body {
          .tab-content {
            .email-details {
              margin: 0 0 20px 0;
            }

            .email-editor {
              margin: 0 0 20px 0;
              max-width: 50vw;
              overflow: auto;

              .rt-editor {
                .DraftEditor-root {
                  overflow: auto;
                  max-height: 250px;

                  & > div > div > div {
                    min-height: 250px;
                  }
                }
              }
            }

            .email-attachments {
              margin: 0 0 20px 0;
            }

            .button-group {
              display: flex;
              justify-content: flex-end;
              background-color: var(--white);
              flex-direction: row;

              .ib-wrap {
                margin: 0 10px 0 0;
                height: 30px;
                max-width: 110px;

                .ib-icon {
                  min-width: 30px;
                }

                .ib-button {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .modal-content {
      min-height: 700px;
    }
  }
}
.ReactModal__Body--open {
  overflow: hidden;
}
