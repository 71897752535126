.file-cabinet {
  background-color: var(--manin-background);
}

.show-table {
  padding: 20px 17px 0 20px;
  table {
    width: 75%;
    border-collapse: collapse;
    td {
      padding: 7.5px 0 7.5px 0;
      font-size: 16px;
      color: var(--text);
    }
    th {
      padding-bottom: 5px;
      border-bottom: 1px solid var(--white);
      font-size: 13px;
      &:first-child {
        padding-left: 20px;
      }
    }
    tr {
      border-bottom: 1px solid var(--border-outline);
      width: 90%;
    }
  }

  td:last-child {
    align-self: flex-end;
  }
  .td-icon {
    color: var(--main-blue);
    cursor: pointer;
    .td-name {
      padding-left: 5px;
    }
  }
  .td-name {
    color: var(--main-blue);
    cursor: pointer;
  }
  .delete-file {
    height: 35px;
  }
}
