.opening-time-form {
  width: 400px;

  h1 {
    color: var(--text);
    font-size: 12px;
  }

  .form-button-line {
    display: flex;

    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }
}
