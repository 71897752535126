.vehicle-run-row {
  .cube-meters {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: fit-content;
    margin: auto;
  }

  .vrr-package-item {
    margin-bottom: 6px;
  }
}
