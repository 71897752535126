.form-section {
  .form-section-body {
    .finalize-run-wrap {
      max-width: 300px;

      .fr-known-values {
        padding: 10px 0;
        margin: 10px 0;
        border-top: 1px solid var(--border-outline);
        border-bottom: 1px solid var(--border-outline);

        .fr-kv {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .fr-success {
            color: var(--green);
          }

          .fr-fail {
            color: var(--red);
          }

          :first-child {
            font-weight: 600;
          }

          :last-child {
            text-align: right;
          }

          &:not(:last-child) {
            margin: 0 0 5px 0;
          }
        }
      }

      .form-field {
        display: grid;
        grid-template-columns: 100px 170px;
      }

      .fr-submit {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 0 0 0;

        .ib-wrap {
          flex: 0;
          width: 100px;
          height: 32px;

          &:not(:last-child) {
            margin: 0 10px 0 0;
          }

          .ib-icon {
            min-width: 32px;
          }

          .ib-button {
            font-weight: bold;
            font-size: 12px;
            max-width: 60px;
          }
        }
      }
    }
  }
}
