.sc-container {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  input {
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    border: 2px solid var(--main-blue);
    border-radius: 3px;
  }
  input:checked {
    background-color: var(--main-blue);
  }

  input[type='checkbox']::after {
    content: '\2713';
    color: var(--white);
    text-align: center;
    opacity: 0;
    font-weight: bold;
    font-size: 10pt;
    align-content: center;
    justify-content: center;
    display: flex;
    height: 16px;
    line-height: 1.2em;
  }

  input[type='checkbox']:checked::after {
    opacity: 1;
  }
}
