.c-table-section {
  .vr-section-footer {
    height: 20px;
  }

  .c-table-header-section {
    .dd-main {
      min-width: 145px;
      margin-right: 10px;

      .dd-current {
        padding: 0 10px;
        height: 27px;

        svg {
          top: 4px;
        }
      }
    }
  }
}
