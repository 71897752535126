.mileage {
  display: grid;
  grid-auto-flow: column;

  // start mileage input & unit
  .vi-container > .vi-type {
    border-radius: 0;
  }
  .vi-container ~ .vi-container > .vi-type {
    border-radius: 0 4px 4px 0;
  }

  // end mileage input & unit
  .vi-container > .vi-input-container .vi-input {
    border-radius: 4px 0 0 4px;
  }
  .vi-container + .vi-container > .vi-input-container .vi-input {
    border-radius: 0;
  }
}
