.ribbon {
  display: flex;
  align-items: center;
  background: var(--alt-background);
  text-align: right;
  padding: 5px 10px;
  height: 35px;
  justify-content: flex-end;

  .split {
    display: flex;
    flex: 1;
  }

  .split:first-child {
    .ib-wrap {
      margin: 0 10px 0 0;
    }
  }

  .split:last-child {
    justify-content: flex-end;
  }

  .ribbon-message {
    display: flex;
    align-items: center;
    color: var(--text);

    svg {
      margin: 2px 10px 0 0;
    }
  }

  .ib-wrap {
    margin: 0 0 0 10px;
    height: 30px;
    max-width: fit-content;

    .ib-icon {
      min-width: 30px;
    }

    .ib-button {
      max-width: 201px;
      font-size: 12px;
    }
  }

  .id-container {
    .id-button {
      margin: 0 0 0 10px;
      height: 30px;
      max-width: fit-content;

      .id-icon {
        min-width: 30px;
      }

      .id-text {
        max-width: 120px;
        font-size: 12px;
      }
    }
  }
}
