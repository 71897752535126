.tariff-charge {
  .page-content {
    padding: 10px;

    h1 {
      font-size: 15px;
      color: var(--dark-grey);
    }

    .form-section {
      &:not(:last-child) {
        margin: 0 0 10px 0;
      }

      .sa-wrap {
        display: flex;

        :not(:last-child) {
          margin: 0 10px 0 0;
        }

        &:not(:last-child) {
          margin: 0 0 10px 0;
        }

        input {
          color: var(--text);
          border: 1px solid var(--border-outline);
          border-radius: 4px;
          padding: 6px 10px;
          outline: none;

          &:focus {
            border: 1px solid var(--dark-grey);
          }
        }

        .form-field {
          display: flex;
          flex: 1;

          .dd-main,
          input {
            flex: 1;
          }
        }

        .ib-wrap {
          display: flex;
          align-items: center;
          background-color: var(--white);
          flex: 0;
          height: 30px;

          .ib-icon {
            height: 30px;
            min-width: 30px;
          }
        }
      }
    }

    .tc-button-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: 110px;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
