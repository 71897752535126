.loading-cog {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% 0 0 0;

  .lc-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .lc-text {
      font-size: 26px;
      color: var(--text);
      font-weight: bold;
    }

    svg {
      font-size: 60px;
    }

    svg.fa-gear {
      color: var(--text);
      margin: 0 0 20px 0;
      animation: spin 4s linear infinite;

      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    svg.fa-triangle-exclamation {
      color: var(--waste-yellow);
    }

    .lc-error-text {
      width: 500px;
      color: var(--text);
      font-size: 16px;
    }
  }
}
